@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --max-width: 1400px;
}

@media (prefers-color-scheme: dark) {
  :root {
  }
}

body {
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.bg-stripe-gradient {
  background: repeating-linear-gradient(
    -55deg,
    #1f8269,
    #1f8269 8px,
    #34a387 8px,
    #34a387 13px
  );
}

.non-draggale {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.spin-wheel-animation {
  transform: rotate(var(--start-rotation-degrees));
}

.spin-wheel-animation.started-spinning {
  animation: spin-wheel var(--start-spinning-time)
      cubic-bezier(0.71, var(--spin-wheel-cb-arg), 0.96, 0.9) 0s 1 normal
      forwards running,
    continueSpin-wheel var(--continue-spinning-time) linear
      var(--start-spinning-time) 1 normal forwards running,
    stopSpin-wheel var(--stop-spinning-time) cubic-bezier(0, 0, 0.35, 1.02)
      var(--stop-spin-time-duration) 1 normal forwards running;
}

@keyframes spin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes continueSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes stopSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--final-rotation-degrees));
  }
}
